body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

h1 {
  font-size: 3rem;
  font-weight: normal;
  margin: 1rem 0 .5rem 0;
}

textarea {
    background-color: transparent;
    border: none;
}
