* {
  padding: 0;
  margin: 0;
}
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

:root {
  --baseSize: 16px;
  --grid: 3rem;

  --gutter: 3rem;
  --gutter--small: 2rem;

  --gridGutter: calc(var(--grid) + var(--gutter));

  --fontSize: 1.5rem;

  --maxWidth: 100rem;
  --minWidth: 22.5rem;

  --smallPadding: 1rem;
  --buttonPadding: calc(var(--smallPadding) / 2) var(--smallPadding);

  --verticalSpace: 6rem;
  --verticalSpace--half: calc(var(--verticalSpace) / 6);

  --black: #33333d;
  --green: #03dac6;
  --pink: #ff0166;
  --lightPink: #f7dbdb;
  --beige: #ede8d7;
  --purple: #6200ee;
  --white: #fff;
  --grey: #494949;

  --warningRed: #b30047;

  --zIndex__top: 100;
  --zIndex__mid: 70;
  --zIndex__bot: 30;
  --zIndex__norm: 1;
}

html {
  font-size: var(--baseSize);
  font-family: Arial, Helvetica, sans-serif;
  color: var(--black);
  background: var(--white);
  height: 100%;
}
body,
.root,
.App {
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 500;
}

h2 {
  font-size: 3rem;
}

h3 {
  font-size: 2rem;
}

p {
  font-size: var(--fontSize);
  margin: var(--fontSize) 0;
}

a {
  color: var(--black);
  text-decoration: none;
  border-bottom: 2px solid var(--green);
  transition: border-color 200ms ease-in;
}

a:hover {
  border-color: var(--pink);
}

button {
  font-size: 1rem;
}

@media (max-width: 750px) {
  .hero__menuItems {
    display: none;
  }

  .new-project-input {
    font-size: 0.7rem;
    padding: 0;
  }
}

.main {
  width: 100%;
  min-width: var(--minWidth);
  margin: 0 auto;
  position: relative;
  background: var(--white);
  height: 100%;
  display: flex;
}

.module {
  width: 100%;
}

.hero {
  background: var(--black);
  color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 3rem;
  margin-bottom: 1rem;
}

.hero__title {
  font-size: 1.5em;
  margin: 0;
  padding: 0 var(--smallPadding);
}

.hero__titleLink {
  color: var(--white);
  text-decoration: none;
  border: 0;
  transition: color 150ms ease-in;
}

.hero__titleLink:hover {
  color: var(--pink);
}

.hero__menuItems {
  width: 100%;
  display: flex;
  list-style: none;
  padding: var(--smallPadding);
}

.hero__menuItem {
  color: var(--white);
  padding: var(--smallPadding);
  text-decoration: none;
  border: 0;
  transition: color 150ms ease-in;
}

.hero__menuItem:hover {
  color: var(--pink);
}

.weekData__input {
  text-align: center;
  background: inherit;
  font-size: 1rem;
  border: 0;
  width: 100%;
}

.currentWeek {
  color: var(--warningRed);
}

.engagementRow__offerRow {
  background: var(--purple);
}

.consultants__heading {
  display: flex;
  justify-content: space-between;
}

.datepicker-input {
  background: transparent;
  border: 0;
  font-size: 1rem;
  text-align: center;
}

.new-project-input {
  font-size: 1rem;
  border: 0;
  background: transparent;
}

.content {
  margin: 0 1rem;
}
